import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import {
  useAuth,
  useUser,
  SuspenseWithPerf,
  useSigninCheck,
  useFirebaseApp,
} from "reactfire";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import OTPInput from "./OTPInput";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BackdropComponent from "./BackdropComponent";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  getAuth,
} from "firebase/auth";
import DALogo from "../assets/images/DALogo.png";

// import PageViewLoggerComponent from "./PageViewLoggerComponent";
const screenX = window.innerWidth;

const styles = {
  body: {
    fontFamily: "Arial, sans-serif",
  },
  loginPage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f6f9fc",
  },
  loginContainer: {
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
  },
};

const LoginFormComponent = () => {
  const [phone, setPhone] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [resendRequestCount, setResendRequestCount] = useState(0);
  const [isOTPSubmitting, setIsOTPSubmitting] = useState(false);
  const [appVerifier, setAppVerifier] = useState(undefined);

  const auth = useAuth();
  auth.languageCode = "en";

  const onSignInSubmit = async () => {
    let phoneNumber = "";

    if (typeof appVerifier === "undefined") {
      console.log("Verifier is undefined");
      return;
    }

    if (typeof phone === "undefined" || phone?.toString().length < 8) {
      toast.warning(`Please enter a valid mobile number ${phone}`);
      return;
    } else {
      phoneNumber = "+61" + phone.toString();
      toast.info(
        `You will receive an SMS to ${phoneNumber} with a one time pin`
      );
    }

    await signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((cR) => {
        setConfirmationResult(cR);
        setResendRequestCount(resendRequestCount + 1);
      })
      .catch((e) => {
        console.log("phone, error", e);
        let message = "";

        switch (e.code) {
          case "auth/captcha-check-failed":
            message = "Captcha error";
            break;

          case "auth/too-many-requests":
            message =
              "Too many attempts from this computer, please wait and try again later";
            break;

          case "auth/invalid-phone-number":
            message = "Phone number format not recognised";
            break;

          default:
            message = e.message;
        }
        toast.error(message);
      });
  };

  const resendOTP = () => {
    setResendRequestCount(resendRequestCount + 1);
    if (typeof appVerifier !== "undefined") {
      appVerifier.render().then((widgetId) => {
        // grecaptcha.reset(widgetId);
        setAppVerifier(widgetId);
        onSignInSubmit();
      });
    }
  };

  const handleChange = (e) => {
    setPhone(e.target.value);
  };

  const confirmOTP = (code) => {
    if (confirmationResult === null) {
      toast.error("Error with verification, please retry");

      return;
    } else if (typeof code === "undefined" || code.length < 6) {
      toast.error("Please enter 6 digit code");
      return;
    } else {
      setIsOTPSubmitting(true);
      const otpcode = code.toString();
      confirmationResult
        .confirm(otpcode)
        .then((result) => {})
        .catch((e) => {
          console.log("otp error", e);
          switch (e.code) {
            case "auth/user-disabled":
              toast.warning(
                "Thank you for registering, an administrator will be in touch with you to enable your account once you are verified as an employee"
              );
              break;
            default:
              toast.error(e.message);
          }
          setIsOTPSubmitting(false);
        });
    }
  };
  // Initialise firebase recapthca

  useEffect(() => {
    // // auth.appVerificationDisabledForTesting = true;
    // auth.settings.appVerificationDisabledForTesting = true;
    const tmpappVerifier = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
      callback: (response, err) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        if (err) console.log("capt", err);
        onSignInSubmit();
      },
      "expired-callback": () => {
        console.log("expired");
      },
    });
    setAppVerifier(tmpappVerifier);
  }, []);

  useState(() => {
    if (confirmationResult !== null) {
    }
  }, [confirmationResult]);
  return (
    <>
      {/* Container to make this in the middle of the page*/}
      {/* Add DALogo image */}
      <img
        src={DALogo}
        alt="Dutton Data Portal"
        style={{
          width: screenX < 600 ? "40%" : "15%",
          marginBottom: 40,
          // float: "left",
          position: "fixed",
          top: 30,
          left: 10,
        }}
      />
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
          marginTop: "12vh",
          padding: "50px",
          backgroundColor: "#f6f9fc",
          // bottom 3d shadow
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Row className={styles.loginPage}>
          <Col className={styles.loginContainer}>
            <br />
            {confirmationResult === null ? (
              <>
                <Row>
                  <Col>
                    <h5 style={{ marginBottom: 20, textAlign: "left" }}>
                      <b>Sign in to your account</b>
                    </h5>
                    <p style={{ textAlign: "left" }}>Phone</p>
                    <InputGroup
                      style={{
                        marginBottom: 20,
                        // increase height
                        height: "50px",
                      }}
                    >
                      <InputGroup.Prepend>
                        <InputGroup.Text
                          style={{
                            marginBottom: 20,
                            // increase height
                            height: "50px",
                          }}
                          id="inputGroup-sizing-sm"
                        >
                          +61
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        style={{
                          marginBottom: 20,
                          // increase height
                          height: "50px",
                          letterSpacing: 1.5,
                        }}
                        aria-label="phone"
                        name="phone"
                        type="tel"
                        value={phone}
                        onChange={handleChange}
                        aria-describedby="inputGroup-sizing-lg"
                      />
                    </InputGroup>
                  </Col>
                </Row>
                <Row
                  style={{ marginTop: 20 }}
                  className="justify-content-md-center"
                >
                  <Col>
                    <InputGroup>
                      <Button
                        id="phone-submit"
                        onClick={onSignInSubmit}
                        block
                        style={{
                          marginBottom: 20,
                          backgroundColor: "#4538ffcc",
                          // increase height
                          height: "50px",
                        }}
                      >
                        Login
                      </Button>
                    </InputGroup>
                    <p style={{ color: "#4538ffcc" }}>
                      Use single-sign-on instead (SSO)
                    </p>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <OTPInput
                  confirm={confirmOTP}
                  phone={phone}
                  isSubmitting={isOTPSubmitting}
                />

                <Button variant="link" onClick={resendOTP}>
                  Resend code
                </Button>
              </>
            )}
            <div id="recaptcha-container"></div>
            <ToastContainer />
            {/* <PageViewLoggerComponent /> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginFormComponent;
