import { useStorage, useUser, useFirebaseApp, useFunctions } from "reactfire";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Spinner from "react-bootstrap/Spinner";
import ImageModalComponent from "./ImageModalComponent";
import { Camera } from "react-bootstrap-icons";
import ProgressBar from "react-bootstrap/ProgressBar";

import InputGroup from "react-bootstrap/InputGroup";
import { useEffect } from "react";
import { useRef } from "react";
import NumberFormat from "react-number-format";
import vision from "react-cloud-vision-api";
import Canvas from "./CanvasComponent";
import PageViewLoggerComponent from "./PageViewLoggerComponent";
import dayjs from "dayjs";
import copy from "copy-to-clipboard";
import { HiClipboardCheck } from "react-icons/hi";
import VehiclePhotos from "./VehiclePhotos";
import { Alert, ButtonGroup, Card } from "react-bootstrap";
import { Field, Formik } from "formik";
import TextField from "./FreightBooking/TextField";
import * as Yup from "yup";
import { httpsCallable } from "firebase/functions";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const dev = true;
var initState = { vin: "", rego: "" };
if (dev) {
  initState.vin = "";
  initState.rego = "";
  initState.stockId = "";
}

const initialCustomerValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone_mobile: "",
  deposit_amount: 0,
  vehicleInfo: {},
};

const validationSchemaPayment = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().required("Required"),
  deposit_amount: Yup.number().required("Required"),
  phone_mobile: Yup.number().required("Required"),
  vehicleInfo: Yup.object().required("Required"),
});

const fixed_stripe_charge_dollars = 0.3;
const variable_stripe_charge_percentage = 0.017;

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// var workerStatus = "";
// const worker = createWorker({
//   logger: (m) => {
//     console.log(m);
//     workerStatus = m;
//   },
// });

function PaymentRequestDeposit() {
  const [vin, setVin] = useState(initState.vin);
  const [stockId, setStockId] = useState(initState.stockId);
  const [rego, setRego] = useState(initState.rego);
  const [photos, setPhotos] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [cvBoundingPoly, setCVBoundinPoly] = useState();

  const [modalShow, setModalShow] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [vehicleInfo, setVehicleInfo] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [isLoadingVinImageRecog, setIsLoadingVinImageRecog] = useState(false);
  const [isLoadingRegoImageRecog, setIsLoadingRegoImageRecog] = useState(false);
  const [vinImageRecogProgress, setVinImageRecogProgress] = useState(0);
  const [isLoadingVicroadsSearch, setIsLoadinVicroadsSearch] = useState(false);
  const [modalDetailsShow, setModalDetailsShow] = useState(false);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY

  const functions = useFunctions();
  const storage = useStorage();
  const vinImageRef = useRef(null);
  const regoImageRef = useRef(null);
  const user = useUser();

  const numericalASC = (a, b) => {
    if (a.position < b.position) {
      return -1;
    }
    if (a.position > b.position) {
      return 1;
    }
    return 0;
  };

  // Customer modal fns
  const hideCustomerInformationModal = () => {
    setModalDetailsShow(false);
  };

  // const getDataByRego = async () => {
  //   if (rego.length === 0 || rego.length < 5) {
  //     toast.warning("Rego doesn't seem correct, please check");
  //     return;
  //   }
  //   var vehicles = db
  //     .collectionGroup("assets")
  //     .where("metadata.regoNo", "==", rego);
  //   vehicles
  //     .get()
  //     .then(function (querySnapshot) {
  //       if (querySnapshot.size > 0) {
  //         // querySnapshot
  //         //   const vehData = doc.data();
  //         toast.success(`Found ${querySnapshot.size} images`);
  //         const images = [];
  //         querySnapshot.forEach((doc) => {
  //           const data = doc.data();
  //           images.push({
  //             position: parseInt(data.metadata.photoPosition),
  //             src: data.mediaLink,
  //             vehicleInfo: data.metadata,
  //           });

  //           //   Sort by photo position
  //           images.sort(numericalASC);
  //         });
  //         // Set photo array
  //         setPhotos(images);

  //         //   console.log(`${vehData}`);
  //       } else {
  //         toast.error("No images found");
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e.message);
  //       toast.error(e.message);
  //     });
  // };
  const draw = (ctx) => {
    const examplePoly = [
      {
        x: 815,
        y: 474,
      },
      {
        x: 1270,
        y: 519,
      },
      {
        x: 1266,
        y: 554,
      },
      {
        x: 812,
        y: 509,
      },
    ];
    // if (modalImage !== null) ctx.drawImage(null, 0, 0);
    // if (modalImage !== null) {
    //   var image = new Image();
    //   image.onload = function () {
    //     ctx.drawImage(image, 0, 0);
    //   };
    //   image.src = modalImage;
    // }

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.beginPath();
    ctx.rect(0, 0, 200, 100);
    ctx.lineWidth = 2;
    ctx.strokeStyle = "green";
    ctx.stroke();
  };

  const copyToClipboard = (v) => {
    copy(v);
  };

  const getData = async (searchByStockId = 0) => {
    var vehicles = null;
    var searchFullVIN = true;
    // If not searching by stock id (searching by VIN)
    if (searchByStockId === 0) {
      if (vin.length === 0 || vin.length < 15) {
        if (vin.length === 6) {
          // If 6 digits, search truncated VIN (last 6 digits)
          searchFullVIN = false;
        } else {
          toast.warning("VIN doesn't seem correct, please check");
          return;
        }
      }

      setSearchResults([]);

      if (searchFullVIN) {
        vehicles = firestore
          .collection("inventory-summary")
          .where("VEHVIN", "==", vin);
      } else {
        vehicles = firestore
          .collection("inventory-summary")
          .where("VINTrunc", "==", vin);
      }
    } else {
      console.log("searching by stock Id", stockId);
      // If searching by stock id
      if (stockId.length === 0) {
        toast.warning("Please enter a stock ID");
        return;
      }

      vehicles = firestore
        .collection("inventory-summary")
        .where("VEHStockNumber", "==", parseInt(stockId));
    }

    await vehicles
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          // querySnapshot
          //   const vehData = doc.data();
          toast.success(`Found ${querySnapshot.size} vehicle in stock`);
          const vehTmp = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data();
            vehTmp.push(data);
          });
          // Set photo array
          setSearchResults(vehTmp);

          //   console.log(`${vehData}`);
        } else {
          toast.error("No vehicle found in stock, check VIN");
        }
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  const showCurrentImageModal = (src) => {
    setModalImage(src);
    setModalShow(true);
  };

  const handleVinChange = (e) => {
    setVin(e.target.value);
  };

  const handleStockIdChange = (e) => {
    setStockId(e.target.value);
  };

  const handleRegoChange = (e) => {
    setRego(e.target.value);
  };

  const handleModalDetails = () => {
    setModalDetailsShow(!modalDetailsShow);
  };

  const handleVinImageChange = async (event) => {
    setVin("");
    setSearchResults([]);
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    console.log(file);
    let fileB64 = null;
    setIsLoadingVinImageRecog(true);
    // Send to GV to detect text
    const ocrResult = null;

    const reader = new FileReader();
    reader.onloadend = async () => {
      // Load img for canvas

      // use a regex to remove data url part
      const base64String = reader.result
        .replace("data:", "")
        .replace(/^.+,/, "");

      // log to console
      // logs wL2dvYWwgbW9yZ...
      console.log("converted to base64");
      fileB64 = base64String;
      setModalImage(URL.createObjectURL(file));
      let req = new vision.Request({
        image: new vision.Image({
          base64: fileB64,
        }),
        features: [new vision.Feature("TEXT_DETECTION")],
      });

      await vision.annotate(req).then((res) => {
        console.log(res);

        let VINLabelIdx = 0;
        let VINValueIdx = 0;
        let VINValue = "";

        res.responses[0].textAnnotations.forEach((ta, idx) => {
          // Search by VIN label order
          // if (ta.description === "VIN") {
          //   VINLabelIdx = idx;
          //   VINValueIdx = idx + 1;
          //   VINValue = res.responses[0].textAnnotations[idx + 1].description;
          // }

          // Search by finding WDC1660242A704804 (17 digit)
          if (ta?.description?.length === 17) {
            VINValue = ta.description;
            setVin(VINValue);
            setCVBoundinPoly(ta.boundingPoly.vertices);
            toast.success(
              "Converted image to VIN, please check and then hit Search"
            );
          }
        });
        setIsLoadingVinImageRecog(false);
        if (VINValue === "") {
          toast.warning(
            "Could not find VIN in image, please try again or enter manually"
          );
        }
      });
    };
    reader.readAsDataURL(file);
  };

  const handleRegoImageChange = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    setIsLoadingRegoImageRecog(true);
    // Send to GV to detect text
    await sleep(4000);
    setIsLoadingRegoImageRecog(false);
    toast.warning(
      "Could not find rego in photo, please try again or type in manually"
    );
    console.log(file);
  };

  const vinImageRefClick = () => {
    if (vinImageRef !== null) {
      vinImageRef.current.click();
    }
  };

  const regoImageRefClick = () => {
    //
    if (regoImageRef !== null) {
      regoImageRef.current.click();
    }
  };

  // Update vehicle info array when photos array changes
  // useEffect(() => {
  //   // Set vehicle info array
  //   if (photos.length > 0 && photos[0].vehicleInfo) {
  //     var vehicleProperties = [];
  //     const tmpVehicleInfo = photos[0].vehicleInfo;
  //     setVehicleInfoObj(tmpVehicleInfo);
  //     for (let prop in tmpVehicleInfo) {
  //       const tmpProp = { key: prop, value: tmpVehicleInfo[prop] };
  //       vehicleProperties.push(tmpProp);
  //     }
  //     setVehicleInfo(vehicleProperties);
  //   }
  // }, [photos]);

  useEffect(() => {
    vision.init({ auth: "AIzaSyBYIpmrTo_qzFvAiG4FAZA4J9AhWGUS4AQ" });
  });

  // Initialise tesseract worker on init load
  // useEffect(() => {
  //   const initWorker = async () => {
  //     await worker.load();
  //     await worker.loadLanguage("eng");
  //     await worker.initialize("eng");
  //   };
  //   initWorker();
  // }, []);

  // useEffect(() => {
  //   toast.info(workerStatus);
  // }, [workerStatus]);

  return (
    <>
      {/* START VIN SEARCH FIELD */}
      <Container>
        <Row style={{ paddingBottom: 15 }}>
          <Col>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="VIN - Search Full VIN or last 6 digits"
                onChange={(e) => handleVinChange(e)}
                value={vin}
              ></Form.Control>
              <InputGroup.Append>
                <Button variant="info" onClick={vinImageRefClick}>
                  {isLoadingVinImageRecog ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <>
                      <Camera />
                    </>
                  )}
                  <input
                    disabled={isLoadingVinImageRecog}
                    type="file"
                    id="vin-image"
                    ref={vinImageRef}
                    style={{ display: "none" }}
                    onChange={handleVinImageChange}
                  />
                </Button>

                <Button variant="dark" onClick={() => getData(0)}>
                  Search by VIN
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
      </Container>
      {/* END VIN SEARCH FIELD */}

      {/* START STOCK ID SEARCH FIELD */}
      <Container>
        <Row style={{ paddingBottom: 15 }}>
          <Col>
            <InputGroup>
              <Form.Control
                type="text"
                placeholder="Stock ID"
                onChange={(e) => handleStockIdChange(e)}
                value={stockId}
              ></Form.Control>
              <InputGroup.Append>
                {/* <Button variant="info" onClick={vinImageRefClick}>
                  {isLoadingVinImageRecog ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <>
                      <Camera />
                    </>
                  )}
                  <input
                    disabled={isLoadingVinImageRecog}
                    type="file"
                    id="vin-image"
                    ref={vinImageRef}
                    style={{ display: "none" }}
                    onChange={handleVinImageChange}
                  />
                </Button> */}

                <Button variant="dark" onClick={() => getData(1)}>
                  Search by Stock ID
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Row>
      </Container>
      {/* END STOCK ID SEARCH FIELD */}

      {/* START Vehicle details modal */}
      {/* <Modal
        show={modalDetailsShow}
        size="lg"
        onHide={() => setModalDetailsShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Vehicle information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Property</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {vehicleInfo.map((prop, idx) => {
                return (
                  <tr key={idx}>
                    <td>{prop.key}</td>
                    <td>{prop.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal> */}
      {/* <Button variant="primary" onClick={handleModalDetails} size="lg">
        Show details
      </Button> */}
      {/* END Vehicle details modal */}

      <ToastContainer />
      <Container>
        <Row>
          {/* <Col md={{ span: 3, offset: 4 }}> */}
          <Col>
            <Image src={modalImage} fluid width={300} />
            {/* <Canvas draw={draw} /> */}
          </Col>
        </Row>
      </Container>

      <br />
      <Container fluid>
        {searchResults.map((veh, idx) => {
          return (
            <>
              {/* {JSON.stringify(veh, null, 2)} */}
              <Table striped bordered hover key={idx}>
                <thead>
                  <tr>
                    <th>Property</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Stock No</td>
                    <td>{veh.VEHStockNumber}</td>
                  </tr>
                  <tr>
                    <td>Current Status</td>
                    <td>{veh.CurrentStatus}</td>
                  </tr>
                  <tr>
                    <td>Year</td>
                    <td>{veh.VEHModelYear}</td>
                  </tr>
                  <tr>
                    <td>Make</td>
                    <td>{veh.VEHMake}</td>
                  </tr>
                  <tr>
                    <td>Model</td>
                    <td>{veh.VEHModel}</td>
                  </tr>
                  <tr>
                    <td>Variant</td>
                    <td>{veh["Variant & Series"]}</td>
                  </tr>
                  <tr>
                    <td>Colour</td>
                    <td>{veh.VEHColour1}</td>
                  </tr>
                  <tr>
                    <td>Odometer</td>
                    <td>
                      <NumberFormat
                        displayType="text"
                        thousandSeparator={true}
                        value={veh.VEHOdo}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Branch / Dealership</td>
                    <td>{veh.branch_desc}</td>
                  </tr>
                  <tr>
                    <td>Current location / yard</td>
                    <td>{veh.VEHYard}</td>
                  </tr>
                  <tr>
                    <td>Buyer</td>
                    <td>{veh.Buyer}</td>
                  </tr>
                  <tr>
                    <td>VIN</td>
                    <td>
                      {veh.VEHVIN}{" "}
                      <Button
                        onClick={() => copyToClipboard(veh.VEHVIN)}
                        size="sm"
                        variant="outline-dark"
                      >
                        <HiClipboardCheck size={20} />
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>Age in stock</td>
                    <td>
                      {dayjs().diff(
                        dayjs.unix(veh.Purchase_Date.seconds),
                        "day",
                        false
                      )}
                      &nbsp;day(s)
                    </td>
                  </tr>
                  <tr>
                    <td>Vicroads serial</td>
                    <td>{veh.vicroadsserial}</td>
                  </tr>
                </tbody>
              </Table>
              <div>
                {/* START ACTION BUTTONS SECTION */}
                <Container style={{ marginBottom: 10 }}>
                  <ButtonGroup size="sm">
                    {vehicleInfo ? (
                      <Button
                        onClick={() => {
                          if (
                            ![
                              "IN-STOCK",
                              "PENDING",
                              "SOLD - NOT DELIVERED",
                            ].includes(veh.CurrentStatus)
                          ) {
                            toast.error(
                              "Cannot take deposit for vehicles not in stock"
                            );
                            return;
                          }
                          setSelectedVehicle(veh);
                          setModalDetailsShow(true);
                        }}
                        variant="outline-primary"
                      >
                        Generate Deposit Payment Request for{" "}
                        {veh.VEHStockNumber}
                      </Button>
                    ) : null}
                  </ButtonGroup>
                </Container>
                {/* END ACTION BUTTONS SECTION */}
              </div>
              <VehiclePhotos vehicle={veh} />
            </>
          );
        })}
      </Container>
      <PageViewLoggerComponent />

      {/* START  Deposit Payment Request Modal  */}
      <Modal
        show={modalDetailsShow}
        size="lg"
        onHide={hideCustomerInformationModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Generate payment request - {selectedVehicle?.VEHStockNumber}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <h6>
                {selectedVehicle?.VEHModelYear} {selectedVehicle?.VEHMake}{" "}
                {selectedVehicle?.VEHModel}{" "}
                {selectedVehicle["Variant & Series"]}
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>
                {selectedVehicle?.branch_desc} - {selectedVehicle?.VEHYard}
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <h6>
                Approx. Asking price:{" "}
                <NumberFormat
                  thousandSeparator={true}
                  prefix={"$"}
                  displayType="text"
                  value={selectedVehicle["Asking Price"]}
                />
              </h6>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card style={{ padding: 15 }}>
                <Formik
                  initialValues={initialCustomerValues}
                  enableReinitialize={true}
                  validationSchema={validationSchemaPayment}
                  // validateOnChange={true}
                  // validateOnBlur={true}
                  onSubmit={async (values, { resetForm, errors }) => {
                    const {
                      VEHYear,
                      VEHStockNumber,
                      VEHVIN,
                      VEHModel,
                      branch_desc,
                      branch_id,
                      VEHMake,
                      db,
                      VEHModelYear,
                      CurrentStatus,
                    } = { ...selectedVehicle };
                    values["vehicleInfo"] = {
                      VEHYear,
                      VEHStockNumber,
                      VEHVIN,
                      VEHModel,
                      branch_desc,
                      branch_id,
                      VEHMake,
                      db,
                      VEHModelYear,
                      CurrentStatus,
                    };
                    values.userEmail = user.data.email;
                    values.paymentType = "DEPOSIT";

                    let payload = values;

                    console.log("Dep", values.deposit_amount);

                    // Set fees
                    payload.fixed_stripe_charge_dollars =
                      fixed_stripe_charge_dollars;
                    payload.variable_stripe_charge_percentage =
                      variable_stripe_charge_percentage;

                    if (
                      values.deposit_amount > selectedVehicle["Asking Price"]
                    ) {
                      toast.error(
                        "Deposit amount cannot be greater than the asking price"
                      );
                      return;
                    }
                    //  to a maximum of $5,000 or 10%
                    // of the asking price, whichever is lower
                    if (
                      values.deposit_amount > 5000 &&
                      values.deposit_amount >
                        0.1 * selectedVehicle["Asking Price"]
                    ) {
                      toast.error(
                        "Deposit amount cannot be greater than $5,000 and 10% of the asking price"
                      );
                      return;
                    }

                    if (
                      values.deposit_amount === 0 ||
                      values.deposit_amount === "" ||
                      values.deposit_amount === null ||
                      values.deposit_amount === undefined ||
                      isNaN(values.deposit_amount) ||
                      values.deposit_amount < 0
                    ) {
                      toast.error(
                        "Please choose an appropriate deposit amount"
                      );
                      return;
                    }

                    if (
                      !["IN-STOCK", "PENDING", "SOLD - NOT DELIVERED"].includes(
                        values?.vehicleInfo?.CurrentStatus
                      )
                    ) {
                      toast.error(
                        "Deposits can only be taken for vehicles that are In Stock"
                      );
                      return;
                    }

                    var generatePaymentLinkFromStripe = httpsCallable(
                      functions,
                      "generatePaymentLinkFromStripe"
                    );

                    await generatePaymentLinkFromStripe(payload)
                      .then((result) => {
                        var sanitizedMessage = result.data;
                        if (sanitizedMessage === false) {
                          throw "Error";
                        } else {
                          toast.success("Payment link sent");
                          hideCustomerInformationModal();
                        }
                      })
                      .catch((e) => {
                        toast.error(e);
                        console.log(e);
                      });
                  }}
                >
                  {/* Callback function containing Formik state and helpers that handle common form actions */}
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    isSubmitting,
                    setFieldValue,
                    resetForm,
                  }) => (
                    <Form autocomplete="off">
                      <>
                        {/* <pre>
                          <code></code> {JSON.stringify(values, null, 2)}
                        </pre> */}
                        {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                        <TextField
                          fieldname={`firstName`}
                          label="First name"
                          placeholder="John"
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />

                        {/* <ErrorMessage name={`location.address`}>
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage> */}
                        <TextField
                          fieldname={`lastName`}
                          label="Last name"
                          placeholder="Smith"
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />

                        <TextField
                          fieldname={`phone_mobile`}
                          label="Phone"
                          placeholder="(03) 8722 1234"
                          type="tel"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                        <TextField
                          fieldname={`email`}
                          label="Email"
                          // placeholder="510123"
                          type="text"
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                        <Form.Group controlId="deposit_amount">
                          <Form.Label>Deposit Amount:{"  "}</Form.Label>
                          <NumberFormat
                            style={{
                              fontWeight: 500,
                              fontSize: "1.5em",
                              color: "green",
                            }}
                            value={values.deposit_amount}
                            displayType="text"
                            thousandSeparator={true}
                            prefix={" $"}
                          />
                          <br />
                          Customer will be charged{" "}
                          <b>
                            <i>(inclusive of merchant fees)</i>
                          </b>
                          :
                          <NumberFormat
                            style={{
                              fontWeight: 500,
                              fontSize: "1.5em",
                              color: "red",
                            }}
                            value={Math.round(
                              (values.deposit_amount +
                                fixed_stripe_charge_dollars) /
                                (1 - variable_stripe_charge_percentage)
                            )}
                            displayType="text"
                            thousandSeparator={true}
                            prefix={" $"}
                          />
                          <br />
                          <br />
                          <p>
                            <i>
                              Use a combinations of the buttons below to set the
                              deposit amount.
                            </i>
                          </p>
                          <p>
                            <i>
                              Deposits are limited to a maximum of $5,000 and
                              10% of the asking price.
                            </i>
                          </p>
                          <ButtonGroup size="sm" className="mb-2">
                            {user.data.email ===
                            "j.jayaram@duttongroup.com.au" ? (
                              <Button
                                variant={"outline-info"}
                                onClick={() => {
                                  setFieldValue(
                                    "deposit_amount",
                                    values.deposit_amount + 1
                                  );
                                }}
                              >
                                + $1 - TESTONLY
                              </Button>
                            ) : null}
                            <Button
                              variant={"outline-info"}
                              onClick={() => {
                                setFieldValue(
                                  "deposit_amount",
                                  values.deposit_amount + 500
                                );
                              }}
                            >
                              + $500
                            </Button>
                            <Button
                              variant={"outline-info"}
                              onClick={() => {
                                setFieldValue(
                                  "deposit_amount",
                                  values.deposit_amount + 1000
                                );
                              }}
                            >
                              + $1,000
                            </Button>
                            <Button
                              variant={
                                values.deposit_amount === 2000
                                  ? "success"
                                  : "outline-info"
                              }
                              onClick={() => {
                                setFieldValue(
                                  "deposit_amount",
                                  values.deposit_amount + 2000
                                );
                              }}
                            >
                              + $2,000
                            </Button>
                            <Button
                              variant={"outline-info"}
                              onClick={() => {
                                setFieldValue(
                                  "deposit_amount",
                                  values.deposit_amount + 5000
                                );
                              }}
                            >
                              + $5,000
                            </Button>
                            <Button
                              variant={"outline-danger"}
                              onClick={() => {
                                setFieldValue("deposit_amount", 0);
                              }}
                            >
                              Clear amount
                            </Button>
                          </ButtonGroup>
                        </Form.Group>

                        <ButtonGroup block style={{ width: "100%" }}>
                          <Button
                            variant="danger"
                            type="reset"
                            onClick={() => {
                              // setSelectedLeadDetails(initialCustomerValues);
                              handleReset();
                            }}
                          >
                            Clear all fields
                          </Button>
                          <Button variant="success" onClick={handleSubmit}>
                            {isSubmitting ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              "Send payment link to customer"
                            )}
                          </Button>
                        </ButtonGroup>
                      </>
                    </Form>
                  )}
                </Formik>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Alert variant="info">
                Clicking "Send payment link to customer" will SMS the customer a
                link to a Stripe page where the customer will be required to
                enter their credit card details. <br />
                <br />
                When the customer successfully completes the transaction, an
                email recepit will be sent to you and also to the
                stripe_receipts@ shared mailbox
              </Alert>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup size="md">
            {vehicleInfo ? (
              <Button
                onClick={hideCustomerInformationModal}
                variant="outline-danger"
              >
                Cancel
              </Button>
            ) : null}{" "}
          </ButtonGroup>
        </Modal.Footer>
      </Modal>

      {/* END Deposit Payment Request Modal  */}
      {/* <Button
        variant="warning"
        onClick={() => {
          db.collection("inStockInventory")
            .add({
              VEHYear: 2022,
              "Asking Price": 76950,
              "Variant & Series": "XLT DUAL CAB P703 MY22",
              CurrentStatus: "IN-STOCK",
              VEHStockNumber: 524861,
              VEHVIN: "MNACMFF70NW238698",
              VEHModel: "RANGER",
              branch_desc: "AMW - DANDENONG",
              branch_id: 19,
              VEHMake: "FORD",
              db: "ezyauto",
              VINTrunc: "238698",

              VEHModelYear: 2022,
            })
            .then((r) => {
              toast.success("Added test vehicle 524861");
            });
        }}
      >
        Load test vehicle
      </Button> */}
    </>
  );
}

export default PaymentRequestDeposit;
