import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { HiClipboardCheck } from "react-icons/hi";
import NumberFormat from "react-number-format";
import { Container } from "react-trello";
import { useFunctions } from "reactfire";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import VehiclePhotos from "./VehiclePhotos";
import { httpsCallable } from "firebase/functions";

// @COMPATIBILITY
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const copyToClipboard = (v) => {
  copy(v);
};

const StockCardByVinComponent = (props) => {
  const [results, setResults] = useState([]);
  const [stockCard, setStockCard] = useState({});
  const [loading, setLoading] = useState(false);
  const [inspectionReport, setInspectionReport] = useState(null);

  // const firebase = useFirestore(); // @COMPATIBILITY
  const firestore = firebase.firestore(); // @COMPATIBILITY
  const functions = useFunctions();

  const getInspectionReport = async (vin) => {
    if (vin.length < 16) {
      toast.warning(
        "Something went wrong, could not retrieve inspection report"
      );
      console.log("VIN missing");
      return;
    }
    try {
      setLoading(true);
      console.log("Attempting to retrieve inspection report");
      const payload = { vin };
      var getSugarCRMInspectionReport = httpsCallable(
        functions,
        "getSugarCRMInspectionReport"
      );
      // var getSugarCRMInspectionReport = firebase
      //   .functions()
      //   .httpsCallable("getSugarCRMInspectionReport");
      setInspectionReport(null);
      await getSugarCRMInspectionReport(payload).then((r) => {
        if (r?.data === false) {
          toast.error("Not authorised");
          return;
        }
        if (r?.error) {
          toast.error(r.error[0].message);
          return;
        }
        if (r?.data?.length > 0) {
          toast.success(`Found reports`);
        }

        setInspectionReport(r.data);
      });
    } catch (e) {
      toast.warning(e.message);
    }
    setLoading(false);
  };

  const downloadAttachmentFromSugar = async (attachmentId) => {
    const fileUrl = `https://crm.sellyourcarfast.com.au/rest/v11_16/Notes/${attachmentId}/file/filename?force_download=1&platform=base`;
    window.open(fileUrl, "_blank");
    setLoading(false);
  };

  useEffect(() => {
    const vin = props.match.params.vin;
    console.log(vin);

    if (vin.length > 6) {
      firestore
        .collection("inventory-summary")
        .where("VEHVIN", "==", vin)
        .get()
        .then((snapshot) => {
          let tmpResults = [];
          snapshot.forEach((d) => {
            const data = d.data();
            tmpResults.push(data);
          });
          tmpResults = tmpResults.sort((a, b) => {
            return parseInt(a.VEHStockNumber) > parseInt(b.VEHStockNumber)
              ? -1
              : 1;
          });
          setResults(tmpResults);
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  }, [props.match.params.vin]);

  return (
    <>
      {/* <pre>{JSON.stringify(stockCard, null, 2)}</pre> */}
      <ToastContainer />
      <Container>
        {results?.map((stockCard, idx) => {
          return (
            <Card key={idx} style={{ marginBottom: 25 }}>
              <Card.Body>
                <Table bordered hover style={{ fontSize: "12px" }}>
                  <thead>
                    <tr
                      style={{
                        backgroundColor:
                          stockCard?.CurrentStatus === "IN-STOCK"
                            ? "#2ECC71"
                            : "#5DADE2",
                        color: "black",
                      }}
                    >
                      <th>Property</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Stock No</td>
                      <td>{stockCard?.VEHStockNumber}</td>
                    </tr>
                    <tr style={{ backgroundColor: "#D4E6F1" }}>
                      <td>
                        <b>Current Status</b>
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            stockCard?.CurrentStatus === "IN-STOCK"
                              ? "#2ECC71"
                              : "#D4E6F1",
                          color: "black",
                        }}
                      >
                        <b>{stockCard?.CurrentStatus}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Year</td>
                      <td>{stockCard?.VEHModelYear}</td>
                    </tr>
                    <tr style={{ backgroundColor: "#D4E6F1" }}>
                      <td>Make</td>
                      <td>{stockCard?.VEHMake}</td>
                    </tr>
                    <tr>
                      <td>Model</td>
                      <td>{stockCard?.VEHModel}</td>
                    </tr>
                    <tr style={{ backgroundColor: "#D4E6F1" }}>
                      <td>Variant</td>
                      <td>{stockCard["Variant & Series"]}</td>
                    </tr>
                    <tr>
                      <td>Colour</td>
                      <td>{stockCard?.VEHColour1}</td>
                    </tr>
                    <tr style={{ backgroundColor: "#D4E6F1" }}>
                      <td>Odometer</td>
                      <td>
                        <NumberFormat
                          displayType="text"
                          thousandSeparator={true}
                          value={stockCard?.VEHOdo}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>Branch / Dealership</b>
                      </td>
                      <td>
                        <b>{stockCard?.branch_desc}</b>
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#D4E6F1" }}>
                      <td>Current location / yard</td>
                      <td>{stockCard?.VEHYard}</td>
                    </tr>
                    <tr>
                      <td>Buyer</td>
                      <td>{stockCard?.Buyer}</td>
                    </tr>
                    <tr style={{ backgroundColor: "#D4E6F1" }}>
                      <td>VIN</td>
                      <td>
                        {stockCard?.VEHVIN}{" "}
                        <Button
                          style={{
                            backgroundColor: "#138D75",
                            color: "white",
                          }}
                          onClick={() => copyToClipboard(stockCard?.VEHVIN)}
                          size="sm"
                          variant="outline-dark"
                        >
                          <HiClipboardCheck size={20} />
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td>Age in stock</td>
                      <td>
                        {dayjs().diff(
                          dayjs.unix(stockCard?.Purchase_Date?.seconds),
                          "day",
                          false
                        )}
                        &nbsp;day(s)
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <VehiclePhotos
                          stockNumber={stockCard?.VEHStockNumber}
                        />
                      </td>
                    </tr>
                    {stockCard?.db === "ezyauto" &&
                    stockCard?.branch_id === 13 ? (
                      <tr>
                        <td colSpan={2}>
                          <Button
                            disabled={loading}
                            onClick={() =>
                              getInspectionReport(stockCard?.VEHVIN)
                            }
                            variant="outline-primary"
                          >
                            {loading ? (
                              <Spinner animation="border" />
                            ) : (
                              "Lookup inspection report"
                            )}
                          </Button>
                        </td>
                      </tr>
                    ) : null}
                    {inspectionReport &&
                    stockCard?.db === "ezyauto" &&
                    stockCard?.branch_id === 13 ? (
                      <>
                        <tr>
                          <td colSpan={2}>
                            <b>
                              Found {inspectionReport?.length || 0} report(s)
                            </b>
                            {inspectionReport?.length > 0 ? (
                              <>
                                <br />
                                <i>
                                  You need to be logged into SugarCRM to view
                                  this report. Otherwise you may get an error
                                  message from sugar. <br />
                                  <b style={{ textAlign: "left" }}>
                                    It might look something like this:
                                  </b>
                                  <br />
                                  <pre style={{ textAlign: "left" }}>
                                    {JSON.stringify(
                                      {
                                        error: "need_login",
                                        error_message:
                                          "No valid authentication for user.",
                                      },
                                      null,
                                      2
                                    )}
                                  </pre>
                                </i>
                              </>
                            ) : null}
                          </td>
                        </tr>

                        {inspectionReport[0]?.attachment_list?.records?.map(
                          (att, idxrep) => {
                            return (
                              <tr key={idxrep}>
                                <td colSpan={2}>
                                  <Button
                                    variant="outline-success"
                                    onClick={() =>
                                      downloadAttachmentFromSugar(att?.id)
                                    }
                                  >
                                    Click to download: {att.filename}
                                  </Button>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </>
                    ) : null}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          );
        })}
      </Container>
    </>
  );
};

export default StockCardByVinComponent;
