import { ToastContainer, toast } from "react-toastify";
import BackdropComponent from "../BackdropComponent";
import {
  Row,
  Col,
  Button,
  InputGroup,
  Form,
  Container,
  Spinner,
  Table,
  Badge,
  ButtonGroup,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  setDoc,
  doc,
  orderBy,
  limit,
} from "firebase/firestore";
import { useFunctions, useUser } from "reactfire";

const stickyTableHeaderStyle = {
  position: "sticky",
  margin: "0 0 0 0",
  top: -1,
  backgroundColor: "#566573",
  color: "white",
  fontSize: 12,
};

const TEST_AUCTION_VEHICLES = require("../../assets/testdata/auction_internal_tender_vehicles_test_060924.json");
const TEST_AUCTION_DATA = {};
const TEST_AUCTION_MEMBERS = [
  {
    uid: "",
    displayName: "",
    email: "",
    phoneNumber: "",
    role: "",
    status: "",
    ownBranches: ["D1 - MELBOURNE"],
  },
];

const InternalTenders = () => {
  //   Hooks
  const functions = useFunctions();
  const firestore = getFirestore();
  const user = useUser();

  //   const getTestAuctionVehicles = async () => {
  //     const yesterdayUnix = dayjs().subtract(1, "day").unix();

  //     const auctionVehiclesRef = collection(firestore, "inventory-summary");
  //     const q = query(
  //       auctionVehiclesRef,
  //       where("CurrentStatus", "==", "IN-STOCK"),
  //       where("db", "in", ["sycf", "duttongwa"])
  //       //   where("Purchase_Date", ">=", yesterdayUnix)
  //     );
  //     const querySnapshot = await getDocs(q);
  //     let auctionVehicles = [];
  //     // log size of snapshot
  //     console.log("Found:", querySnapshot.size);
  //     querySnapshot.forEach((doc) => {
  //       const id = doc.id;
  //       const data = doc.data();
  //       auctionVehicles.push({ id, ...data });
  //     });

  //     if (auctionVehicles.length === 0) {
  //       return [];
  //     }
  //     // // Filter null values for Purchase_Date
  //     // auctionVehicles = auctionVehicles.filter((vehicle) => {
  //     //   return vehicle.Purchase_Date !== null;
  //     // });

  //     // Filer for vehicles that arrived today

  //     let auctionVehiclesFromToday = auctionVehicles.filter((vehicle) => {
  //       return vehicle.Purchase_Date?.seconds >= yesterdayUnix;
  //     });

  //     // Add DD/MM/YYYY format for Purchase_Date to array
  //     auctionVehiclesFromToday = auctionVehiclesFromToday.map((vehicle) => {
  //       return {
  //         ...vehicle,
  //         Purchase_Date_Formatted: dayjs
  //           .unix(vehicle.Purchase_Date.seconds)
  //           .format("DD/MM/YYYY"),
  //       };
  //     });

  //     return auctionVehiclesFromToday;
  //   };

  const createNewAuction = async (
    auctionData,
    auctionVehicles,
    auctionMembers
  ) => {
    console.log("Creating new auction");
    console.log(auctionData, auctionVehicles, auctionMembers);
  };

  // Load test
  useEffect(() => {
    // getTestAuctionVehicles().then((data) => {
    //   console.log(data);
    // });
  }, []);
  return (
    <>
      <BackdropComponent loading={false} />
      <ToastContainer />
      <Container>
        <Row>
          <Col>
            <h5>Internal tenders</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              onClick={() =>
                createNewAuction(
                  TEST_AUCTION_DATA,
                  TEST_AUCTION_VEHICLES,
                  TEST_AUCTION_MEMBERS
                )
              }
            >
              Create new auction
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default InternalTenders;
